import React, { createContext, useEffect } from "react";


export const Themes = {
    'default': {
        'color': {
            'background-light': {
                'rgb': 'rgb(244, 252, 255)',
                '_rgb': {r: 244, g: 252, b: 255},
                'hex': '#F4FCFF',
            },
            'background-dark': {
                'rgb': 'rgb(61, 68, 102)',
                '_rgb': {r: 61, g: 68, b: 102}, 
                'hex': '#3D4466',
            },
            'text-light': {
                'rgb': 'rgb(244, 252, 255)',
                '_rgb': {r: 244, g: 252, b: 255}, 
                'hex': '#F4FCFF',
            },
            'text-blue': {
                'rgb': 'rgb(13, 57, 138)',
                '_rgb': {r: 13, g: 57, b: 138}, 
                'hex': '#0D398A',
            },
            'text-dark': {
                'rgb': 'rgb(61, 68, 102)',
                '_rgb': {r: 61, g: 68, b: 102}, 
                'hex': '#3D4466',
            },
            'icon-dark': {
                'rgb': 'rgb(61, 68, 102)',
                '_rgb': {r: 61, g: 68, b: 102}, 
                'hex': '#123C89',
            },
            'logo-blue': {
                'rgb': 'rgb(13, 57, 138)',
                '_rgb': {r: 13, g: 57, b: 138}, 
                'hex': '#0D398A',
            },
        },
        'fontSize': {
            'xx-small': 'xx-small',
            'x-small': 'x-small',
            'small': 'small',
            'medium': 'medium',
            'large': 'large',
            'x-large': 'x-large',
            'xx-large': 'xx-large',
            'xxx-large': 'xxx-large',
        },
        'global': {
            'logo-width': '100px',
        }
    }
}


const ThemeContext = createContext(Themes.default)

const Theme = ({children, theme}) => {
    useEffect( () => {
        /* Set as CSS variables: theme colors */
        const colorKeys = Object.keys(theme.color)
        for (let i = 0; i < colorKeys.length; i++){
            document.documentElement.style.setProperty(`--color-${colorKeys[i]}-hex`, theme.color[colorKeys[i]].hex);
            document.documentElement.style.setProperty(`--color-${colorKeys[i]}-rgb`, theme.color[colorKeys[i]].rgb);   
            document.documentElement.style.setProperty(`--color-${colorKeys[i]}-rgb-r`, theme.color[colorKeys[i]]._rgb.r);   
            document.documentElement.style.setProperty(`--color-${colorKeys[i]}-rgb-g`, theme.color[colorKeys[i]]._rgb.g);   
            document.documentElement.style.setProperty(`--color-${colorKeys[i]}-rgb-b`, theme.color[colorKeys[i]]._rgb.b);   
        }
        /* Set as CSS variables: font sizes */
        const fontSizeKeys = Object.keys(theme.fontSize)
        for (let i = 0; i < fontSizeKeys.length; i++){
            document.documentElement.style.setProperty(`--font-size-${fontSizeKeys[i]}`, theme.fontSize[fontSizeKeys[i]]);
        }
        /* Set as CSS variables: global variables */
        const globalKeys = Object.keys(theme.global)
        for (let i = 0; i < globalKeys.length; i++){
            document.documentElement.style.setProperty(`--${globalKeys[i]}`, theme.global[globalKeys[i]]);
        }
    }, [theme])

    return (
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    )
}

export default Theme