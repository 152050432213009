import React from 'react';
import UrlRedirect from './components/urlRedirect/UrlRedirect';
import Theme, { Themes } from './components/Theme/Theme';
import LanguagePicker from './components/LanguagePicker/LanguagePicker'
/* Styling */
import './App.css';
import './assets/animations/loading.css';
import './assets/animations/appear.css';
import './assets/animations/pulsing.css';


function App() {
  return (
    <Theme theme={Themes.default}>
      <div className="App">
        <LanguagePicker />
        <UrlRedirect />
      </div>
    </Theme>
  );
}

export default App;
