import React, { useContext } from "react";
import './LanguagePicker.css'
import { ReactComponent as EarthIcon } from './../../assets/icons/earth.svg'
import { I18NContext } from "../../i18n/i18n";
import { SUPPORTED_LANGUAGES } from "../../i18n/config";


const LanguagePicker = () => {
    const { language, setlanguage } = useContext(I18NContext)
    
    return (
        <div className="language-picker-container">
            <div 
                className={
                    `language-picker-option
                    ${language === SUPPORTED_LANGUAGES.en ? ' language-picker-option-selected ' : ''}`
                }
                onClick={() => {setlanguage(SUPPORTED_LANGUAGES.en)}}
            >
                EN
            </div>
            <EarthIcon className="language-picker-icon" />
            <div
                className={
                    `language-picker-option
                    ${language === SUPPORTED_LANGUAGES.cz ? ' language-picker-option-selected ' : ''}`
                }
                onClick={() => {setlanguage(SUPPORTED_LANGUAGES.cz)}}
            >
                CZ
            </div>
        </div>
    )
}

export default LanguagePicker