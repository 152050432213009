import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { SUPPORTED_LANGUAGES } from './config'
import { createContext } from "react";


/* Separate into multiple files if translations grow */
const resources = {
    "en": {
        "translation": {
            "error_message_page_not_found": "Page not found",
            "error_message_cant_find_page_you_are_looking_for": "We are sorry but we can't find the page you are looking for.",
            "you_will_be_redirected_to_page": "You will be redirected shortly to page <0>blue-style.cz</0>."
        }
    },
    "cz": {
        "translation": {
            "error_message_page_not_found": "Stránka nenalezena",
            "error_message_cant_find_page_you_are_looking_for": "Je nám líto, ale nemůžeme najít stránku, kterou hledáte.",
            "you_will_be_redirected_to_page": "Do pár vteřin budete přesměrováni na stránku <0>blue-style.cz</0>."
        }
    }
}


i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: SUPPORTED_LANGUAGES.cz, // Default lanugage
    });

export default i18n;


export const I18NContext = createContext(i18n)
export const I18NextProvider = ({i18n, language=SUPPORTED_LANGUAGES.cz, children}) => {
    /**
     * Provide languages to components through this provider and I18NContext
     * to ensure that React triggers rerender if the context/language changes.
     */

    const localStorageKey = 'i18nextLng'
    
    const [languageState, setLanguageState] = useState(language)
    // Wrapper, set language as state and also to local storage
    const changeLanguage = (lng) => {
        localStorage.setItem(localStorageKey, lng)
        setLanguageState(lng)
        i18n.changeLanguage(lng)
    }

    /* If language key present in local storage -> give priority */
    useEffect(() => {
        const localStorageLangKey = localStorage.getItem(localStorageKey)
        if(localStorageLangKey){
            /* If supported */
            if (Object.keys(SUPPORTED_LANGUAGES).includes(localStorageLangKey)){
                changeLanguage(localStorageLangKey)
            }else{
                changeLanguage(language)
            }
        }else{
            changeLanguage(language)
        }
    }, [language])


    return (
        <I18NContext.Provider value={{
            i18n: i18n,
            language: languageState,
            setlanguage: changeLanguage
        }}
        >
            {children}
        </I18NContext.Provider>
    )
}
