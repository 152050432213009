import React from 'react';
import './index.css';
import axios from 'axios';
import LoadingPage from '../LoadingPage';
import ErrorPage from '../ErrorPage';


class UrlRedirect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      isPosting: false,
      isPosted: false,
      errorType: '',
      messageType: ''
    };
  }

  componentDidMount() {
    var urlParam = ''
    // eslint-disable-next-line no-restricted-globals
    var urlParams = window.location.pathname;
    if(urlParams !== undefined){
      
      var pathes = window.location.pathname.split('/');
      urlParam = pathes[pathes.length-1]

      // Call api only when urlParam not empty
      if (urlParam !== ''){
        this.setState({ url: urlParam, isPosted: true });
        axios.get(process.env.REACT_APP_API_URL + '/marketing/v1/shortUrls/' + urlParam)
        .then((response) => {
          if(response.status === 200){
            window.location.href = response.data.Url
        }
        },(error) => {
          this.setState({isError: true, isPosted: false})
        });
      }else{
        this.setState({isError: true, isPosted: false})
      }
    }else{
      this.setState({isError: true, isPosted: false})
    }
  };
  
  render() {
    return (
      <div>
        {
          this.state.isPosted ?
          <LoadingPage/> : 
          this.state.isError ?
          <ErrorPage/> : ''
        }
      </div>
    )
  }
}

export default UrlRedirect;
