import React from 'react';
import './index.css'
import BlueStyleLogo from './../../assets/images/logo-blue-style-square.png'


const LoadingPage = () => {
  return (
    <div className='loading-page-container'>
      <div className='animation-appear'>
        <img className='animation-pulse loading-page-blue-style-logo' src={BlueStyleLogo} alt='Blue Style logo' />
      </div>
    </div>
  )
};

export default LoadingPage;