import React from 'react';
import BlueStyleLogo from '../../assets/images/logo-blue-style-square.png'
import { useTranslation, Trans } from 'react-i18next';
import './index.css'


const ErrorPage = () => {
  const { t } = useTranslation();
  // Link where to auto redirecte when <ErrorPage /> is shown
  const redirectTo = 'https://blue-style.cz/';
  const redirectAfter = 5000;

  /* Redirect user to 'redirectTo' after 'redirectAfter' */
  setTimeout(() => {
    window.location.href = redirectTo
  }, redirectAfter)
  
  return (
    <div className="animation-appear error-page-container">
      <img src={BlueStyleLogo} alt='Blue Style Logo' />
      <div className='error-page-text-container'>
        <h1>{t('error_message_page_not_found')}</h1>
        <p>
          {t('error_message_cant_find_page_you_are_looking_for')}<br />
          <Trans 
            i18nKey='you_will_be_redirected_to_page'
            components={[<a href={redirectTo} />,]}
          />
        </p>
      </div>
    </div>
  )
};

export default ErrorPage;